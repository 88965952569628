import { BaseModule, BaseModuleProps, Content, PageContent } from "../../types";
import React from "react";
import ResponsiveImage from "../structure/ResponsiveImage";
import { MfLink } from "../structure/MfLink";
import ImageUtil from "../../util/ImageUtil";
import { useSiteData } from "../../providers/SiteDataProvider";

const TeaserColumn: React.FC<
  Omit<BaseModuleProps, "pageContent"> & {
    pageContent: PageContent["content"];
    leftOrRight: "left" | "right";
  }
> = ({ pageContent, leftOrRight }) => {
  const isRight = leftOrRight === "right";
  const content: Partial<Content> = pageContent[leftOrRight] || {};
  const image = ImageUtil.getImageCompat(content);

  return (
    <div
      className={
        isRight
          ? "col-sm-6 m-teaser-container__right-column"
          : "col-sm-6 m-teaser-container__left-column"
      }
    >
      <div
        className={`m-teaser h-background h-background--halfwidth-${
          isRight ? "right" : "left"
        } `}
        data-t-name="Teaser"
        data-t-id="36"
      >
        <div className="m-teaser__inner">
          {!image ? null : (
            <div className="a-image" data-t-name="Image">
              <figure>
                <ResponsiveImage img={image} />
              </figure>
            </div>
          )}

          <div className="m-teaser__content">
            <h3
              dangerouslySetInnerHTML={{
                __html: content.headline || "",
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: content.text || "",
              }}
            />
            <MfLink link={content.link} className="m-teaser__link">
              <span
                className="btn btn-default"
                dangerouslySetInnerHTML={{
                  __html: content.button || "",
                }}
              />
            </MfLink>
          </div>
        </div>
      </div>
    </div>
  );
};
const TeaserTwoCol: BaseModule = ({ pageContent }) => {
  return (
    <div className="teaserContainer abstractTeaser abstractComponent">
      <div className="m-teaser-container">
        <TeaserColumn pageContent={pageContent} leftOrRight={"left"} />
        <TeaserColumn pageContent={pageContent} leftOrRight={"right"} />
        <div style={{ clear: "both" }} />
      </div>
    </div>
  );
};

export default TeaserTwoCol;
