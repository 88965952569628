import Link from 'next/link';
import React from 'react';
import { useSiteData } from '../../providers/SiteDataProvider';

export const MfLink: React.FC<{ link?: string; className?: string }> = ({
    link,
    children,
    className,
}) => {
    const { getRelativeOrAbsoluteUrl } = useSiteData();
    if (!link) return null;

    const href = getRelativeOrAbsoluteUrl(link || '');

    if (href.isExternal) {
        return (
            <a
                className={className}
                target={'_blank'}
                rel="noopener noreferrer"
                href={href?.href}
            >
                {children}
            </a>
        );
    } else {
        return (
            <Link passHref={true} href={href?.href}>
                <a className={className}>{children}</a>
            </Link>
        );
    }
};
